/* eslint-disable no-undef */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import companyApi from 'lib/api/company';
import { CommonPayload } from '../../../../../@types/action-payload';
import {
  investorTypeList,
  marketBoardList,
  transactionTypeList,
} from './constants';
import { iGetTopBrokerParams, iTopBrokerData } from './types';

interface PaginationState {
  page: number;
  limit: number;
  isScrollable: boolean;
}

interface BandarDetectorState {
  brokerSummary: {
    detector: Record<string, any>;
    summary: {
      brokers_buy: Array<any>;
      brokers_sell: Array<any>;
      symbol: string;
    };
    from: string;
    to: string;
  };
  data: {
    detector: Record<string, any>;
    summary: {
      brokers_buy: Array<any>;
      brokers_sell: Array<any>;
      symbol: string;
    };
    from: string;
    to: string;
  };
  brokerActivity: {
    summary: {
      brokers_buy: Array<any>;
      brokers_sell: Array<any>;
      symbol: string;
    };
    from: string;
    to: string;
    activeBroker: string;
    brokerList: Array<any>;
    pagination: PaginationState;
    transactionType: string;
    marketBoard: string;
    investorType: string;
  };
  topBrokers: iTopBrokerData;
  isLoading: boolean;
  error: any;
  message: string;
  // TODO: Remove this object when permanent solution applied to the pages
  brokerSummaryForm: {
    from: string;
    to: string;
  };
}

const defaultSummary = {
  brokers_buy: [],
  brokers_sell: [],
  symbol: '',
};

const defaultPagination = {
  page: 1,
  limit: 50,
  isScrollable: true,
};

// Initial state
const initialState: BandarDetectorState = {
  brokerSummary: {
    detector: {},
    summary: defaultSummary,
    from: '',
    to: '',
  },
  data: {
    detector: {},
    summary: defaultSummary,
    from: '',
    to: '',
  },
  brokerActivity: {
    summary: defaultSummary,
    from: '',
    to: '',
    activeBroker: '',
    brokerList: [],
    pagination: defaultPagination,
    transactionType: transactionTypeList[0].value,
    marketBoard: marketBoardList[1].value,
    investorType: investorTypeList[0].value,
  },
  topBrokers: {
    date: {
      from: '',
      to: '',
      idx: '',
    },
    list: [],
  },
  isLoading: true,
  error: null,
  message: '',
  // TODO: Remove this object when permanent solution applied to the pages
  brokerSummaryForm: {
    from: '',
    to: '',
  },
};

// Selector
const bandarDetectorState = (state) => state.mainLayout.bandarDetector;
export const selectors = createSelector(
  bandarDetectorState,
  (state: BandarDetectorState) => ({
    data: state.data,
    detector: state.data.detector,
    summary: state.data.summary,
    brokerSummary: state.brokerSummary,
    brokerActivity: state.brokerActivity,
    topBrokers: state.topBrokers.list,
    topBrokerDate: state.topBrokers.date,
    isLoading: state.isLoading,
    // TODO: Remove this line when permanent solution applied to the pages
    brokerSummaryForm: state.brokerSummaryForm,
  }),
);

// Action types
const CONTEXT = '@side-widget/bandarDetector';

const actionType = {
  GET_BANDAR_DETECTOR: `${CONTEXT}/GET_BANDAR_DETECTOR`,
  GET_BROKER_LIST: `${CONTEXT}/GET_BROKER_LIST`,
  GET_BROKER_ACTIVITY: `${CONTEXT}/GET_BROKER_ACTIVITY`,
  GET_BROKER_ACTIVITY_NEXT_PAGE: `${CONTEXT}/GET_BROKER_ACTIVITY_NEXT_PAGE`,
  GET_TOP_BROKER: `${CONTEXT}/GET_TOP_BROKER`,
};

interface GetBandarDetectorParams {
  symbol: string;
  params: {
    from?: Date;
    to?: Date;
  };
}

interface GetBrokerListParams {
  page: number;
  limit: number;
  group: string;
}

interface GetBrokerActivityParams {
  brokerCode: string;
  params: {
    from: string;
    to: string;
    transaction_type: string;
    market_board: string;
    page: number;
    limit: number;
    investor_type: string;
  };
}

type GetTopBrokerPayload = CommonPayload;

type GetBrokerActivityPayload = CommonPayload;

type GetBandarDetectorPayload = CommonPayload;

// Side effects
export const effects = {
  getBandarDetector: createAsyncThunk<
    GetBandarDetectorPayload,
    GetBandarDetectorParams
  >(actionType.GET_BANDAR_DETECTOR, async ({ symbol, params }) => {
    try {
      const response = await companyApi.getMarketDetector(symbol, params);

      if (!response.data) {
        throw new Error('Attempt to get market detector failed!');
      }

      const { data, error, message } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
  getTopBroker: createAsyncThunk<GetTopBrokerPayload, iGetTopBrokerParams>(
    actionType.GET_TOP_BROKER,
    async (params) => {
      try {
        const response = await companyApi.getTopBroker(params);

        if (!response.data) {
          throw new Error('Attempt to get top broker failed!');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),

  getBrokerList: createAsyncThunk<
    GetBrokerActivityPayload,
    GetBrokerListParams
  >(actionType.GET_BROKER_LIST, async (params) => {
    try {
      const response = await companyApi.getBrokerList(params);

      if (!response.data) {
        throw new Error('Attempt to get broker list failed!');
      }

      const { data, error, message } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),

  getBrokerActivity: createAsyncThunk<
    GetBrokerActivityPayload,
    GetBrokerActivityParams
  >(actionType.GET_BROKER_ACTIVITY, async ({ brokerCode, params }) => {
    try {
      const response = await companyApi.getMarketDetectorActivity(
        brokerCode,
        params,
      );

      if (!response.data) {
        throw new Error('Attempt to get broker activity failed!');
      }

      const { data, error, message } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),

  getBrokerActivityNextPage: createAsyncThunk<
    GetBrokerActivityPayload,
    GetBrokerActivityParams
  >(
    actionType.GET_BROKER_ACTIVITY_NEXT_PAGE,
    async ({ brokerCode, params }) => {
      try {
        const response = await companyApi.getMarketDetectorActivity(
          brokerCode,
          params,
        );

        if (!response.data) {
          throw new Error('Attempt to get broker activity failed!');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {
  setActiveBroker: (
    state: BandarDetectorState,
    action: { payload: string },
  ) => {
    state.brokerActivity.activeBroker = action.payload;

    // reset pagination to default when active broker is changed
    if (state.brokerActivity.pagination.page !== 1) {
      state.brokerActivity.pagination = defaultPagination;
    }
  },
  setPagination: (state: BandarDetectorState) => {
    const { brokers_buy: brokersBuy, brokers_sell: brokersSell } =
      state.brokerActivity.summary;
    const sizeData = Math.max(brokersBuy.length, brokersSell.length);
    const { page, isScrollable } = state.brokerActivity.pagination;
    if (isScrollable && sizeData >= 50) {
      state.brokerActivity.pagination = {
        ...state.brokerActivity.pagination,
        page: page + 1,
      };
    }
  },
  resetPagination: (state: BandarDetectorState) => {
    state.brokerActivity.pagination = defaultPagination;
  },
  // TODO: Remove this reducer when permanent solution applied to the pages
  setBrokerSummaryForm: (
    state: BandarDetectorState,
    action: { payload: { key: string; value: string } },
  ) => {
    const { key, value } = action.payload;
    state.brokerSummaryForm[key] = value;
  },
  setTransactionType: (
    state: BandarDetectorState,
    action: { payload: string },
  ) => {
    state.brokerActivity.transactionType = action.payload;
  },
  setMarketBoard: (state: BandarDetectorState, action: { payload: string }) => {
    state.brokerActivity.marketBoard = action.payload;
  },
  setInvestorType: (
    state: BandarDetectorState,
    action: { payload: string },
  ) => {
    state.brokerActivity.investorType = action.payload;
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getBandarDetector.pending,
      (state: BandarDetectorState) => {
        state.isLoading = true;
        state.error = null;
      },
    )
    .addCase(
      effects.getBandarDetector.fulfilled,
      (
        state: BandarDetectorState,
        action: PayloadAction<GetBandarDetectorPayload>,
      ) => {
        const { data = {}, error, message } = action.payload;

        if (error) {
          state.error = error;
        }

        if (!data.bandar_detector || !data.broker_summary) {
          state.brokerSummary.detector = {};
          state.brokerSummary.summary = defaultSummary;
          state.brokerSummary.from = '';
          state.brokerSummary.to = '';

          state.error = null;
        }

        if (data.bandar_detector && data.broker_summary) {
          state.brokerSummary.detector = data.bandar_detector;
          state.brokerSummary.summary = data.broker_summary;
          state.brokerSummary.from = data.from;
          state.brokerSummary.to = data.to;

          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBandarDetector.rejected,
      (state: BandarDetectorState, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBrokerList.fulfilled,
      (
        state: BandarDetectorState,
        action: PayloadAction<GetBrokerActivityPayload>,
      ) => {
        const { data, error, message } = action.payload;

        if (error) state.error = error;

        if (data) {
          state.brokerActivity.brokerList = data;
          if (!state.brokerActivity.activeBroker) {
            state.brokerActivity.activeBroker = data[0].code;
          }
          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBrokerActivity.pending,
      (state: BandarDetectorState) => {
        state.isLoading = true;
        state.error = null;
      },
    )
    .addCase(
      effects.getBrokerActivity.fulfilled,
      (
        state: BandarDetectorState,
        action: PayloadAction<GetBrokerActivityPayload>,
      ) => {
        const { data, error, message } = action.payload;
        const { broker_summary: newSummary } = data;

        if (error) {
          state.error = error;
        }

        if (!newSummary) {
          state.brokerActivity.summary = defaultSummary;
          state.brokerActivity.from = '';
          state.brokerActivity.to = '';
        } else {
          state.brokerActivity.summary = newSummary;
          state.brokerActivity.from = data.from;
          state.brokerActivity.to = data.to;
          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBrokerActivity.rejected,
      (state: BandarDetectorState, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBrokerActivityNextPage.pending,
      (state: BandarDetectorState) => {
        state.isLoading = true;
        state.error = null;
      },
    )
    .addCase(
      effects.getBrokerActivityNextPage.fulfilled,
      (
        state: BandarDetectorState,
        action: PayloadAction<GetBrokerActivityPayload>,
      ) => {
        const { data, error, message } = action.payload;
        const { broker_summary: newSummary } = data;

        if (error) state.error = error;

        if (!newSummary) {
          state.brokerActivity.pagination = {
            ...state.brokerActivity.pagination,
            isScrollable: false,
          };
        } else {
          const sizeBrokersBuy = newSummary.brokers_buy.length;
          const sizeBrokersSell = newSummary.brokers_sell.length;
          if (sizeBrokersBuy || sizeBrokersSell) {
            state.brokerActivity.summary = {
              brokers_buy: [
                ...state.brokerActivity.summary.brokers_buy,
                ...newSummary.brokers_buy,
              ],
              brokers_sell: [
                ...state.brokerActivity.summary.brokers_sell,
                ...newSummary.brokers_sell,
              ],
              symbol: '',
            };
          } else {
            state.brokerActivity.pagination.isScrollable = false;
          }
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getBrokerActivityNextPage.rejected,
      (state: BandarDetectorState, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    )
    .addCase(effects.getTopBroker.pending, (state: BandarDetectorState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.getTopBroker.fulfilled,
      (
        state: BandarDetectorState,
        action: PayloadAction<GetTopBrokerPayload>,
      ) => {
        const { data, error, message } = action.payload;

        if (error) {
          state.error = error;
        }

        if (!data) {
          state.topBrokers.list = [];
          state.topBrokers.date = { from: '', to: '', idx: '' };

          state.error = null;
        } else {
          state.topBrokers.list = data.list;
          state.topBrokers.date = data.date;

          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getTopBroker.rejected,
      (state: BandarDetectorState, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    );
};

const bandarDetectorSlice = createSlice({
  name: 'bandarDetector',
  initialState,
  reducers,
  extraReducers,
});

export default bandarDetectorSlice;
