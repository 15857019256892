import { color, utils } from '@stockbit/ui';
import { Td } from './styled';
import { iBrokerOrder, iBrokerPeriod } from './types';

const { hexOpacity: percentToHex } = utils;

const cellBgType = {
  green: color.lightGreen,
  red: color.monaLisa,
  lightGreen: color.lightGreen + percentToHex(50),
  lightRed: color.monaLisa + percentToHex(50),
  grey: color.gainsboro,
};

const accDistColor = {
  Neutral: cellBgType.grey,
  Dist: cellBgType.lightRed,
  'Small Dist': cellBgType.red,
  'Normal Dist': cellBgType.red,
  'Big Dist': cellBgType.red,
  Acc: cellBgType.lightGreen,
  'Small Acc': cellBgType.green,
  'Normal Acc': cellBgType.green,
  'Big Acc': cellBgType.green,
};

const brokerType = {
  Lokal: 'local',
  Pemerintah: 'bumn',
  Asing: 'foreign',
  BROKER_GROUP_LOCAL: 'local',
  BROKER_GROUP_GOVERNMENT: 'bumn',
  BROKER_GROUP_FOREIGN: 'foreign',
};

const defaultTransactionData = [
  {
    key: 'top1',
    label: <Td weight="bold">Top 1</Td>,
    volume: <Td> - </Td>,
    percent: <Td> - </Td>,
    price: <Td> - </Td>,
    accDist: <Td align="center">-</Td>,
  },
  {
    key: 'top3',
    label: <Td weight="bold">Top 3</Td>,
    volume: <Td> - </Td>,
    percent: <Td> - </Td>,
    price: <Td> - </Td>,
    accDist: <Td align="center">-</Td>,
  },
  {
    key: 'top5',
    label: <Td weight="bold">Top 5</Td>,
    volume: <Td> - </Td>,
    percent: <Td> - </Td>,
    price: <Td> - </Td>,
    accDist: <Td align="center">-</Td>,
  },
  {
    key: 'average',
    label: <Td weight="bold">Average</Td>,
    volume: <Td> - </Td>,
    percent: <Td> - </Td>,
    price: <Td> - </Td>,
    accDist: <Td align="center">-</Td>,
  },
];

const defaultBrokerData = [
  {
    key: 'broker',
    label: <Td weight="bold">Broker</Td>,
    buyer: <Td> - </Td>,
    seller: <Td> - </Td>,
    hash: <Td> - </Td>,
    accDist: <Td align="center">-</Td>,
  },
];

const defaultSummaryData = [
  {
    key: 'summary_1',
    label: <Td>Net Volume</Td>,
    value: <Td> - </Td>,
    empty: <Td />,
  },
  {
    key: 'summary_2',
    label: <Td>Net Value</Td>,
    value: <Td> - </Td>,
    empty: <Td />,
  },
  {
    key: 'summary_3',
    label: <Td>Average (Rp)</Td>,
    value: <Td> - </Td>,
    empty: <Td />,
  },
];

const defaultTransactionListData = [
  {
    by: <Td> - </Td>,
    bval: <Td> - </Td>,
    bavg: <Td> - </Td>,
    sl: <Td> - </Td>,
    sval: <Td> - </Td>,
    savg: <Td> - </Td>,
  },
];

const BANDAR_DETECTOR_MENU_KEY = {
  BROKER_SUMMARY: 'BROKER_SUMMARY',
  TOP_BROKER: 'TOP_BROKER',
  BROKER_ACTIVITY: 'BROKER_ACTIVITY',
};

const TRANSACTION_TYPE = {
  UNSPECIFIED: 'TRANSACTION_TYPE_UNSPECIFIED',
  NET: 'TRANSACTION_TYPE_NET',
  GROSS: 'TRANSACTION_TYPE_GROSS',
};

const MARKET_BOARD = {
  UNSPECIFIED: 'MARKET_BOARD_UNSPECIFIED',
  ALL: 'MARKET_BOARD_ALL',
  REGULER: 'MARKET_BOARD_REGULER',
  TUNAI: 'MARKET_BOARD_TUNAI',
  NEGO: 'MARKET_BOARD_NEGO',
};

const INVESTOR_TYPE = {
  UNSPECIFIED: 'INVESTOR_TYPE_UNSPECIFIED',
  ALL: 'INVESTOR_TYPE_ALL',
  FOREIGN: 'INVESTOR_TYPE_FOREIGN',
  DOMESTIC: 'INVESTOR_TYPE_DOMESTIC',
};

const transactionTypeList = [
  { label: 'Net', value: TRANSACTION_TYPE.NET },
  { label: 'Gross', value: TRANSACTION_TYPE.GROSS },
];

const marketBoardList = [
  { label: 'All Market', value: MARKET_BOARD.ALL },
  { label: 'Regular', value: MARKET_BOARD.REGULER },
  { label: 'Tunai', value: MARKET_BOARD.TUNAI },
  { label: 'Nego', value: MARKET_BOARD.NEGO },
];

const investorTypeList = [
  { label: 'All Investor', value: INVESTOR_TYPE.ALL },
  { label: 'Domestic', value: INVESTOR_TYPE.DOMESTIC },
  { label: 'Foreign', value: INVESTOR_TYPE.FOREIGN },
];

const TIME_FILTER: Array<{
  label: string;
  value: iBrokerPeriod | 'Custom';
}> = [
  {
    label: 'Last 1 Day',
    value: 'TB_PERIOD_LAST_1_DAY',
  },
  {
    label: 'Last 7 Days',
    value: 'TB_PERIOD_LAST_7_DAYS',
  },
  {
    label: 'Last 1 Month',
    value: 'TB_PERIOD_LAST_1_MONTH',
  },
  /* {
    label: 'Last 1 Year',
    value: 'TB_PERIOD_LAST_1_YEAR',
  }, */
  {
    label: 'Custom',
    value: 'Custom',
  },
];

const SORT: { asc: iBrokerOrder; desc: iBrokerOrder } = {
  asc: 'ORDER_BY_ASC',
  desc: 'ORDER_BY_DESC',
};

const emptyData = {
  buy_value: '',
  code: '',
  name: '',
  net_value: '',
  sell_value: '',
  total_frequency: '',
  total_value: '',
  total_volume: '',
  group: 'BROKER_GROUP_UNSPECIFIED',
};

export {
  accDistColor,
  brokerType,
  defaultTransactionData,
  defaultBrokerData,
  defaultSummaryData,
  defaultTransactionListData,
  BANDAR_DETECTOR_MENU_KEY,
  TRANSACTION_TYPE,
  MARKET_BOARD,
  INVESTOR_TYPE,
  transactionTypeList,
  marketBoardList,
  investorTypeList,
  TIME_FILTER,
  SORT,
  emptyData,
};
